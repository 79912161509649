import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Pages500 from "pages/404/pages-500"
import Pages404 from "pages/404/pages-404"

import ForgetPwd from "pages/Authentication/ForgetPwd"
import ShopDetails from "pages/Shops/SingleView"
import CreateShop from "pages/Shops/Crud/Create"
import ShopUserDetails from "pages/Shops/SingleView/shopuser/UpdateView"

const commonRoute = [
  { path: "/dashboard", component: Dashboard },

  { path: "/shop/:id", component: ShopDetails },
  { path: "/shop/user/:id", component: ShopUserDetails },

  { path: "/make/shop", component: CreateShop },
  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
]

export { publicRoutes, commonRoute }
