import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ left: 0 }}>
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Shop Inventory.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by{" "}
                <a style={{ color: "#1781BB" }} href="https://osperb.com/">
                  Osperb
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
