import {
  GET_LIST_SHOPS_SUCCESS,
  GET_LIST_SHOPS_FAIL,
  GET_LIST_SHOP_DETAIL_SUCCESS,
  GET_LIST_SHOP_DETAIL_FAIL,
  CREATE_LIST_SHOP_SUCCESS,
  CREATE_LIST_SHOP_FAIL,
  UPDATE_LIST_SHOP_SUCCESS,
  UPDATE_LIST_SHOP_FAIL,
  DELETE_LIST_SHOP_SUCCESS,
  DELETE_LIST_SHOP_FAIL,
  GET_LIST_SHOPS,
  GET_LIST_SHOP_DETAIL,
  UPDATE_LIST_SHOP,
  CREATE_LIST_SHOP,
  DELETE_LIST_SHOP,
  CREATE_SHOP_USER,
  CREATE_SHOP_USER_SUCCESS,
  CREATE_SHOP_USER_FAIL,
  GET_SHOP_USER,
  GET_SHOP_USER_SUCCESS,
  GET_SHOP_USER_FAIL,
  GET_SHOP_USER_DETAIL,
  GET_SHOP_USER_DETAIL_SUCCESS,
  GET_SHOP_USER_DETAIL_FAIL,
  UPDATE_SHOP_USER,
  UPDATE_SHOP_USER_SUCCESS,
  UPDATE_SHOP_USER_FAIL,
  DELETE_SHOP_USER,
  DELETE_SHOP_USER_SUCCESS,
  DELETE_SHOP_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  shops: [],
  shopDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  shopUser: [],
  shopUserDetail: {},
  userLoading: false,
  createdShopUser: {},
}

const Shops = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case GET_LIST_SHOPS:
    case UPDATE_LIST_SHOP:
    case CREATE_LIST_SHOP:
    case DELETE_LIST_SHOP:
      return {
        ...state,
        loading: true,
      }
    case GET_LIST_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload,
        loading: false,
      }

    case GET_LIST_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_LIST_SHOP_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case CREATE_LIST_SHOP_SUCCESS:
      return {
        ...state,
        shops: action.payload,
        loading: false,
      }

    case CREATE_LIST_SHOP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LIST_SHOP_DETAIL_SUCCESS:
      return {
        ...state,
        shopDetail: action.payload,
        loading: false,
        detailLoading: false,
        error: {},
      }

    case GET_LIST_SHOP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_LIST_SHOP_SUCCESS:
      return {
        ...state,
        error: {},
        shopDetail: action.payload,
        loading: false,
      }

    case UPDATE_LIST_SHOP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_LIST_SHOP_SUCCESS:
      return {
        ...state,
        shops: state.shops.filter(
          shop => shop.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_LIST_SHOP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //user
    case CREATE_SHOP_USER:
    case GET_SHOP_USER:
    case GET_SHOP_USER_DETAIL:
    case UPDATE_SHOP_USER:
    case DELETE_SHOP_USER:
      return {
        ...state,
        userLoading: true,
      }

    case CREATE_SHOP_USER_SUCCESS:
      return {
        ...state,
        createdShopUser: action.payload,
        shopUser: {
          ...state.shopUser,
          results: [...state.shopUser.results, { uid: 1, ...action.payload }],
        },
        userLoading: false,
      }

    case CREATE_SHOP_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        userLoading: false,
      }

    case GET_SHOP_USER_SUCCESS:
      return {
        ...state,
        shopUser: action.payload,
        userLoading: false,
      }

    case GET_SHOP_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        userLoading: false,
      }

    case GET_SHOP_USER_DETAIL_SUCCESS:
      return {
        ...state,
        shopUserDetail: action.payload,
        userLoading: false,
      }

    case GET_SHOP_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        userLoading: false,
      }

    case UPDATE_SHOP_USER_SUCCESS:
      return {
        ...state,
        shopUser: action.payload,
        userLoading: false,
      }

    case UPDATE_SHOP_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        userLoading: false,
      }

    case DELETE_SHOP_USER_SUCCESS:
      return {
        ...state,
        shopUser: {
          ...state.shopUser,
          results: state.shopUser.results?.filter(
            user => user.id !== action.payload
          ),
        },
        userLoading: false,
      }

    case DELETE_SHOP_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        userLoading: false,
      }

    default:
      return state
  }
}

export default Shops
