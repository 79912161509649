import Shops from "pages/Shops"
import React from "react"
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import DefualtComponent from "./DefualtComponent"
import DashboardCard from "./MiniCard"

function index() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Shop Inventory | Shops</title>
        </MetaTags>
        <Container fluid>
          {/* <Row>
            <Col sm="4">
              <DefualtComponent />
            </Col>
            <Col sm="8">
              <DashboardCard />
            </Col>
          </Row> */}
          <Shops />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
