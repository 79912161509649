import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router"
import { Button, Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import PropTypes from "prop-types"

//actions
import { getShopDetail, updateShop } from "store/actions"

const UpdateShop = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const { shopDetail, loading, error } = useSelector(state => ({
    loading: state.Shop.loading,
    error: state.Shop.error,
    shopDetail: state.Shop.shopDetail,
  }))
  function handleValidSubmit(values) {
    dispatch(updateShop(values, shopDetail.id))
    window.scroll(0, 0)
    history.push(`/shop/${shopDetail?.id}`)
  }

  useEffect(() => {
    dispatch(getShopDetail(params.id))
  }, [])

  return (
    <div className="sm:p-0" id="update">
      <Row>
        <Col lg={12} className="sm:p-0">
          <Card className="p-3 sm:p-0">
            <CardBody>
              <CardTitle className="h4 mb-4">Update Shop</CardTitle>
              {/* <Alert color="danger" className="mt-2">
                      {error}
                    </Alert> */}
              <AvForm
                className="form-horizontal "
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
              >
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-username-Input"
                    className="col-sm-3 col-form-label"
                  >
                    Shop Name
                  </Label>
                  <Col sm={9}>
                    <AvField
                      id="horizontal-username-Input"
                      className="filePathClass"
                      name="shop_name"
                      type="text"
                      value={shopDetail?.shop_name}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                </div>

                <div className="row mb-4">
                  <Label
                    htmlFor="tel-input"
                    className="col-sm-3 col-form-label"
                  >
                    Phone No
                  </Label>
                  <Col sm={9}>
                    <AvField
                      name="phone"
                      className="form-control"
                      id="tel-input"
                      value={shopDetail?.phone}
                      type="mobile"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter your phone number",
                        },
                        minLength: {
                          value: 10,
                          errorMessage: "Your number must be 10 characters",
                        },
                        maxLength: {
                          value: 10,
                          errorMessage: "Your number must be 10 characters",
                        },
                      }}
                    />
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-email-Input"
                    className="col-sm-3 col-form-label"
                  >
                    Email
                  </Label>
                  <Col sm={9}>
                    <AvField
                      id="horizontal-email-Input"
                      name="email"
                      value={shopDetail?.email}
                      className="form-control"
                      type="email"
                      required
                    />
                  </Col>
                </div>
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-email-Input"
                    className="col-sm-3 col-form-label"
                  >
                    Address
                  </Label>
                  <Col sm={9}>
                    <AvField
                      id="horizontal-email-Input"
                      name="address"
                      value={shopDetail?.address}
                      className="form-control"
                      type="textarea"
                    />
                  </Col>
                </div>

                <div className="row justify-content-end">
                  <Col sm={9}>
                    <div>
                      <Button type="submit" color="success" className="w-md">
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        Update
                      </Button>
                    </div>
                  </Col>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default UpdateShop

UpdateShop.propTypes = {
  history: PropTypes.object,
}
