import {
  GET_LIST_SHOPS,
  GET_LIST_SHOPS_SUCCESS,
  GET_LIST_SHOPS_FAIL,
  GET_LIST_SHOP_DETAIL,
  GET_LIST_SHOP_DETAIL_SUCCESS,
  GET_LIST_SHOP_DETAIL_FAIL,
  CREATE_LIST_SHOP,
  CREATE_LIST_SHOP_SUCCESS,
  CREATE_LIST_SHOP_FAIL,
  UPDATE_LIST_SHOP,
  UPDATE_LIST_SHOP_SUCCESS,
  UPDATE_LIST_SHOP_FAIL,
  DELETE_LIST_SHOP,
  DELETE_LIST_SHOP_SUCCESS,
  DELETE_LIST_SHOP_FAIL,
  CREATE_SHOP_USER,
  CREATE_SHOP_USER_SUCCESS,
  CREATE_SHOP_USER_FAIL,
  GET_SHOP_USER,
  GET_SHOP_USER_SUCCESS,
  GET_SHOP_USER_FAIL,
  GET_SHOP_USER_DETAIL_SUCCESS,
  GET_SHOP_USER_DETAIL,
  GET_SHOP_USER_DETAIL_FAIL,
  UPDATE_SHOP_USER,
  UPDATE_SHOP_USER_SUCCESS,
  UPDATE_SHOP_USER_FAIL,
  DELETE_SHOP_USER,
  DELETE_SHOP_USER_SUCCESS,
  DELETE_SHOP_USER_FAIL,
} from "./actionTypes"

export const getShops = (searchText, page) => ({
  type: GET_LIST_SHOPS,
  payload: { searchText, page },
})

export const getShopsSuccess = shops => ({
  type: GET_LIST_SHOPS_SUCCESS,
  payload: shops,
})
export const getShopsFail = error => ({
  type: GET_LIST_SHOPS_FAIL,
  payload: error,
})

export const getShopDetail = shopId => ({
  type: GET_LIST_SHOP_DETAIL,
  shopId,
})

export const getShopDetailSuccess = shopDetail => ({
  type: GET_LIST_SHOP_DETAIL_SUCCESS,
  payload: shopDetail,
})

export const getShopDetailFail = error => ({
  type: GET_LIST_SHOP_DETAIL_FAIL,
  payload: error,
})

export const createShop = (shop, history) => ({
  type: CREATE_LIST_SHOP,
  payload: { shop, history },
})

export const createShopSuccess = shop => ({
  type: CREATE_LIST_SHOP_SUCCESS,
  payload: shop,
})

export const createShopFail = error => ({
  type: CREATE_LIST_SHOP_FAIL,
  payload: error,
})

export const updateShop = (shop, shopId) => ({
  type: UPDATE_LIST_SHOP,
  payload: { shop, shopId },
})

export const updateShopSuccess = shop => ({
  type: UPDATE_LIST_SHOP_SUCCESS,
  payload: shop,
})

export const updateShopFail = error => ({
  type: UPDATE_LIST_SHOP_FAIL,
  payload: error,
})

export const deleteShop = (shopId, history) => ({
  type: DELETE_LIST_SHOP,
  payload: { shopId, history },
})

export const deleteShopSuccess = shop => ({
  type: DELETE_LIST_SHOP_SUCCESS,
  payload: shop,
})

export const deleteShopFail = error => ({
  type: DELETE_LIST_SHOP_FAIL,
  payload: error,
})

//shop user
export const createShopUser = (user, history) => ({
  type: CREATE_SHOP_USER,
  payload: { user, history },
})

export const createShopUserSuccess = user => ({
  type: CREATE_SHOP_USER_SUCCESS,
  payload: user,
})

export const createShopUserFail = error => ({
  type: CREATE_SHOP_USER_FAIL,
  payload: error,
})

export const getShopUser = (searchText, page, shopId) => ({
  type: GET_SHOP_USER,
  payload: { searchText, page, shopId },
})

export const getShopUserSuccess = users => ({
  type: GET_SHOP_USER_SUCCESS,
  payload: users,
})
export const getShopUserFail = error => ({
  type: GET_SHOP_USER_FAIL,
  payload: error,
})

export const getShopUserDetail = ({ shopUserId, shopId }) => ({
  type: GET_SHOP_USER_DETAIL,
  payload: { shopUserId, shopId },
})

export const getShopUserDetailSuccess = users => ({
  type: GET_SHOP_USER_DETAIL_SUCCESS,
  payload: users,
})
export const getShopUserDetailFail = error => ({
  type: GET_SHOP_USER_DETAIL_FAIL,
  payload: error,
})

export const updateShopUser = ({ shopUser, history, userId, shopId }) => ({
  type: UPDATE_SHOP_USER,
  payload: { shopUser, history, userId, shopId },
})

export const updateShopUserSuccess = users => ({
  type: UPDATE_SHOP_USER_SUCCESS,
  payload: users,
})
export const updateShopUserFail = error => ({
  type: UPDATE_SHOP_USER_FAIL,
  payload: error,
})

export const deleteShopUser = (shopUserId, shopId) => ({
  type: DELETE_SHOP_USER,
  payload: { shopUserId, shopId },
})

export const deleteShopUserSuccess = shopUser => ({
  type: DELETE_SHOP_USER_SUCCESS,
  payload: shopUser,
})

export const deleteShopUserFail = error => ({
  type: DELETE_SHOP_USER_FAIL,
  payload: error,
})
