import React, { useState } from "react"
import {
  Badge,
  Button,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useEffect } from "react"
import { Card, CardBody, Media, Spinner } from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { AvField, AvForm } from "availity-reactstrap-validation"

//actions
import {
  deleteShop,
  deleteShopUser,
  getShopUserDetail,
  updateShopUser,
} from "store/actions"

//componetns
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
// import UpdateShop from "../Crud/Update"
import DeleteModal from "components/Common/DeleteModal"
// import ShopUser from "./shopuser/DatatableTables"

const ShopUserDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()

  const shopId = location?.search

  const { shopUserDetail, loading, userLoading } = useSelector(state => ({
    loading: state.Shop.userLoading,
    userLoading: state.Shop.userLoading,
    shopUserDetail: state.Shop.shopUserDetail,
  }))

  const shopIdNew = shopId.split("?shopId=")[1]

  function handleValidSubmit(e, values) {
    dispatch(
      updateShopUser({
        shopUser: { ...values, shop: shopIdNew },
        history: history,
        userId: params.id,
        shopId: shopId,
      })
    )
  }

  useEffect(() => {
    dispatch(getShopUserDetail({ shopUserId: params.id, shopId: shopId }))
  }, [])

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Shop User" breadcrumbItem="Shop" />
        <Container fluid className="">
          <div className="sm:p-0" id="update">
            <Row>
              <Col lg={12} className="sm:p-0">
                <Card className="p-3 sm:p-0">
                  <CardBody>
                    <div className="w-100 d-flex align-items-center justify-content-between mb-3">
                      <CardTitle className="h4 mb-4">Shop User</CardTitle>
                    </div>
                    {/* <Alert color="danger" className="mt-2">
                      {error}
                    </Alert> */}
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-username-Input"
                          className="col-sm-3 col-form-label"
                        >
                          User Name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="username"
                            type="text"
                            value={shopUserDetail?.username}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-username-Input"
                          className="col-sm-3 col-form-label"
                        >
                          First Name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="first_name"
                            type="text"
                            value={shopUserDetail?.first_name}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-username-Input"
                          className="col-sm-3 col-form-label"
                        >
                          last Name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="last_name"
                            type="text"
                            value={shopUserDetail?.last_name}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="tel-input"
                          className="col-sm-3 col-form-label"
                        >
                          Phone No
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="phone"
                            className="form-control"
                            id="tel-input"
                            value={shopUserDetail?.phone}
                            type="mobile"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter your phone number",
                              },
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-email-Input"
                            name="email"
                            value={shopUserDetail?.email}
                            className="form-control"
                            type="email"
                            required
                          />
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="success"
                              className="w-md"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Update
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default ShopUserDetails

ShopUserDetails.propTypes = {
  history: PropTypes.object,
}
