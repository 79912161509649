import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_LIST_SHOPS,
  GET_LIST_SHOP_DETAIL,
  CREATE_LIST_SHOP,
  UPDATE_LIST_SHOP,
  DELETE_LIST_SHOP,
  CREATE_SHOP_USER,
  GET_SHOP_USER,
  GET_SHOP_USER_DETAIL,
  UPDATE_SHOP_USER,
  DELETE_SHOP_USER,
} from "./actionTypes"
import {
  getShopsSuccess,
  getShopsFail,
  getShopDetailSuccess,
  getShopDetailFail,
  createShopSuccess,
  createShopFail,
  updateShopSuccess,
  updateShopFail,
  deleteShopSuccess,
  deleteShopFail,
  createShopUserSuccess,
  createShopUserFail,
  getShopUserSuccess,
  getShopUserFail,
  getShopUserDetailSuccess,
  getShopUserDetailFail,
  updateShopUserSuccess,
  updateShopUserFail,
  deleteShopUserSuccess,
  deleteShopUserFail,
} from "./actions"
import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const middleWareUrl = "/api/v1"

const getShopsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(`${middleWareUrl}/shop/shop/?search=${searchText && searchText}`)
  } else {
    return get(`${middleWareUrl}/shop/shop/?page=${page ? page : 1}`)
  }
}
const getShopDetailsAPi = shopId => {
  return get(`${middleWareUrl}/shop/shop/${shopId}`)
}
const createShopApi = ({ shop }) => {
  return post(`${middleWareUrl}/shop/shop/`, shop)
}
const updateShopApi = ({ shopId, shop }) => {
  return ApiPut(`${middleWareUrl}/shop/shop/${shopId}/`, shop)
}
const deleteShopApi = ({ shopId }) => {
  return del(`${middleWareUrl}/shop/shop/${shopId}/`)
}

const createShopUserApi = ({ user }) => {
  return post(`${middleWareUrl}/shop/shop-user/`, user)
}
const updateShopUserApi = ({ shopUser, userId, shopId }) => {
  return patch(`${middleWareUrl}/shop/shop-user/${userId}/${shopId} `, shopUser)
}

const getShopUserDetailsAPi = ({ shopUserId, shopId }) => {
  return get(`${middleWareUrl}/shop/shop-user/${shopUserId}${shopId}`)
}

const getShopUserAPi = ({ searchText, page, shopId }) => {
  if (searchText) {
    return get(
      `${middleWareUrl}/shop/shop-user/?shopId=${shopId}&search=${
        searchText && searchText
      }`
    )
  } else {
    return get(
      `${middleWareUrl}/shop/shop-user/?shopId=${shopId}&page=${
        page ? page : 1
      }`
    )
  }
}
const deleteShopUserApi = ({ shopUserId, shopId }) => {
  return del(`${middleWareUrl}/shop/shop-user/${shopUserId}/?shopId=${shopId}`)
}

function* fetchShops({ payload }) {
  try {
    const response = yield call(getShopsAPi, payload)
    yield put(getShopsSuccess(response))
  } catch (error) {
    yield put(getShopsFail(error))
  }
}

function* fetchShopDetail({ shopId }) {
  try {
    const response = yield call(getShopDetailsAPi, shopId)
    yield put(getShopDetailSuccess(response))
  } catch (error) {
    yield put(getShopDetailFail(error))
  }
}
function* onCreateShop({ payload }) {
  try {
    const response = yield call(createShopApi, payload)
    if (response?.id) {
      yield put(createShopSuccess(response))
      payload.history.push("/dashboard")
      doneNotification()
    } else {
      yield put(createShopFail(response))
    }
  } catch (error) {
    yield put(createShopFail(error))
    errorNotification()
  }
}

function* onUpdateShop({ payload }) {
  try {
    const response = yield call(updateShopApi, payload)
    if (response?.id) {
      yield put(updateShopSuccess(response))
      doneNotification()
    } else {
      yield put(updateShopFail(response))
    }
  } catch (error) {
    yield put(updateShopFail(error))
    errorNotification()
  }
}

function* onDeleteShop({ payload }) {
  try {
    const response = yield call(deleteShopApi, payload)
    payload.history.push("/dashboard")
    yield put(deleteShopSuccess(response))
    doneNotification()
  } catch (error) {
    yield put(deleteShopFail(error))
    errorNotification()
  }
}

function* fetchShopUsers({ payload }) {
  console.log("data :", payload)
  try {
    const response = yield call(getShopUserAPi, payload)
    yield put(getShopUserSuccess(response))
  } catch (error) {
    yield put(getShopUserFail(error))
  }
}
function* fetchShopUserDetail({ payload }) {
  try {
    const response = yield call(getShopUserDetailsAPi, payload)
    yield put(getShopUserDetailSuccess(response))
  } catch (error) {
    yield put(getShopUserDetailFail(error))
  }
}
function* onCreateShopUser({ payload }) {
  try {
    const response = yield call(createShopUserApi, payload)
    if (response?.shop) {
      yield put(createShopUserSuccess(response))
      doneNotification()
    } else {
      yield put(createShopUserFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(createShopUserFail(error))
    errorNotification()
  }
}
function* onUpdateShopUser({ payload }) {
  try {
    const response = yield call(updateShopUserApi, payload)
    if (response?.shop) {
      yield put(updateShopUserSuccess(response))
      doneNotification()
      payload.history.goBack()
    } else {
      yield put(updateShopUserFail(response))
    }
  } catch (error) {
    yield put(updateShopUserFail(error))
    errorNotification()
  }
}

function* onDeleteShopUser({ payload }) {
  try {
    const response = yield call(deleteShopUserApi, payload)
    yield put(deleteShopUserSuccess(payload?.shopUserId))
    doneNotification()
  } catch (error) {
    yield put(deleteShopUserFail(error))
    errorNotification()
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}

function doneNotification() {
  Notification({
    type: "success",
    message: "Done",
    title: "",
  })
}

function* ShopsSaga() {
  yield takeEvery(GET_LIST_SHOPS, fetchShops)
  yield takeEvery(GET_LIST_SHOP_DETAIL, fetchShopDetail)
  yield takeEvery(CREATE_LIST_SHOP, onCreateShop)
  yield takeEvery(UPDATE_LIST_SHOP, onUpdateShop)
  yield takeEvery(DELETE_LIST_SHOP, onDeleteShop)
  yield takeEvery(CREATE_SHOP_USER, onCreateShopUser)
  yield takeEvery(GET_SHOP_USER, fetchShopUsers)
  yield takeEvery(GET_SHOP_USER_DETAIL, fetchShopUserDetail)
  yield takeEvery(UPDATE_SHOP_USER, onUpdateShopUser)
  yield takeEvery(DELETE_SHOP_USER, onDeleteShopUser)
}

export default ShopsSaga
