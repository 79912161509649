import React, { useState } from "react"
import { Badge, Button, Col, Container, Row } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useEffect } from "react"
import { Card, CardBody, Media, Spinner } from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"

//actions
import { deleteShop, getShopDetail } from "store/actions"

//componetns
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import UpdateShop from "../Crud/Update"
import DeleteModal from "components/Common/DeleteModal"
import ShopUser from "./shopuser/DatatableTables"

const ShopDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()

  const { shopDetail, loading, detailLoading } = useSelector(state => ({
    loading: state.Shop.loading,
    detailLoading: state.Shop.detailLoading,
    shopDetail: state.Shop.shopDetail,
  }))

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteShop(shopDetail?.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getShopDetail(params.id))
  }, [])

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Shop | Shop Inventory </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Shop" breadcrumbItem="Shops" />
        <Container fluid className="">
          <div className="container-fluid">
            <Row>
              <Col xl="12" className="sm:p-0">
                <Card>
                  <CardBody>
                    {loading ? (
                      <>
                        <Spinner color="info" />
                      </>
                    ) : (
                      <Row>
                        <Col lg="6" md="6" className="mb-4">
                          <Media className="align-self-center" body>
                            <div className="text-muted">
                              <h5 className="mb-2">
                                {shopDetail?.shop_name}&nbsp;({" "}
                                <span className="mb-0 text-capitalize">
                                  Id : {shopDetail?.auto_id}
                                </span>
                                )
                              </h5>

                              <p className="mb-0 text-capitalize mt-1">
                                <i className="bx bx-home-alt "></i>&nbsp;
                                {shopDetail?.address}
                              </p>
                            </div>
                          </Media>
                        </Col>

                        <Col lg="4" md="6" className="d-lg-block">
                          <Media className="align-self-center mb-4" body>
                            <div className="text-muted ">
                              {detailLoading ? (
                                <Spinner />
                              ) : (
                                <>
                                  <h5 className="mb-1">{shopDetail?.email}</h5>
                                  <p className="mb-1">{shopDetail?.phone}</p>
                                </>
                              )}
                            </div>
                            Last Sync :
                            <Badge
                              style={{ cursor: "pointer" }}
                              className="font-size-12 mx-2 mb-2 badge-soft-warning p-2"
                              pill
                            >
                              {shopDetail?.last_sync}
                            </Badge>
                          </Media>
                        </Col>

                        <Col
                          lg="2"
                          className="d-flex align-items-center justify-content-between btn-wrap"
                        >
                          <Link
                            style={{ opacity: 0.8 }}
                            to="#"
                            className={`btn btn-outline-light ${
                              loading && "disabled"
                            }  btn-m`}
                            onClick={handleDelete}
                          >
                            Delete Shop
                            <i className="fas fa-trash text-danger ms-1 bx-tada-hover"></i>
                          </Link>
                          <a href="#update">
                            <Button type="submit" color="info" className="w-md">
                              Update Shop
                            </Button>
                          </a>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>

              <ShopUser />

              {location?.hash && (
                <Col xl="12" className="sm:p-0">
                  <UpdateShop />
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default ShopDetails

ShopDetails.propTypes = {
  history: PropTypes.object,
}
