import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import "../../../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { deleteShopUser, getShopUser } from "store/actions"
import Register from "./Create"
import DeleteModal from "components/Common/DeleteModal"

const ShopUser = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { shopUser, loading, shopDetail } = useSelector(state => ({
    shopUser: state.Shop.shopUser,
    loading: state.Shop.userLoading,
    shopDetail: state.Shop.shopDetail,
  }))

  //pages
  const totalPages = Math.ceil(shopUser?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    if (shopDetail?.id) {
      dispatch(getShopUser(searchText, pageSend(), shopDetail?.id))
    }
  }, [dispatch, page, searchText, shopDetail])

  const columns = [
    {
      dataField: "key",
      text: "Id",
      sort: true,
    },
    {
      dataField: "first_name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const shopuserData = map(shopUser?.results, (item, index) => ({
    ...item,
    key: index + 1,
    action: (
      <div>
        <Link
          to={`/shop/user/${item?.id}?shopId=${shopDetail?.id}`}
          className="btn-light btn-sm"
        >
          View
        </Link>
        <Link
          style={{ opacity: 0.8 }}
          to="#"
          className={`btn  btn-m`}
          onClick={() => handleDelete(item?.id)}
        >
          <i className="fas fa-trash text-danger ms-1 bx-tada-hover"></i>
        </Link>
      </div>
    ),
  }))

  console.log(shopuserData)

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  const [userModal, setUserModal] = useState(false)

  const handleAddUser = () => {
    setUserModal(true)
  }

  const [isOpen, setIsOpen] = useState(false)
  const [userId, setUserId] = useState()

  const handleDelete = id => {
    setUserId(id)
    setIsOpen(true)
  }

  const handleDeleteEvent = () => {
    dispatch(deleteShopUser(userId, shopDetail?.id))
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <Register
        show={userModal}
        onCloseClick={() => setUserModal(false)}
        shopId={shopDetail.id}
      />
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <Col className="col-12">
        <Card>
          <CardBody>
            <CardTitle>Shop Users</CardTitle>
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={shopuserData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <form
                            className="app-search d-lg-block"
                            onChange={e => handleSearch(e)}
                          >
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                defaultValue={searchText}
                              />
                              <span className="bx bx-search-alt" />
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>
                    <Col md="4"></Col>
                    <Col
                      md="4"
                      className="d-flex align-items-center justify-content-end"
                    >
                      <Button
                        type="submit"
                        color="success"
                        className="w-md"
                        onClick={handleAddUser}
                      >
                        Add User
                      </Button>
                    </Col>
                  </Row>
                  {loading ? (
                    <Spinner color="secondary" className="d-block m-auto" />
                  ) : (
                    <>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <MyPagination
                        pages={pages}
                        clcickedPage={page}
                        onNunClick={item => setPage(item)}
                        onNextClick={() => setPage(page + 1)}
                        onPrevClick={() => setPage(page - 1)}
                        onFastNextClick={() => setPage(pages.length)}
                        onFastPrevClick={() => setPage(1)}
                        apiPage={pageSend}
                      />
                    </>
                  )}
                </React.Fragment>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default ShopUser
