/* LIST_SHOPS */
export const GET_LIST_SHOPS = "GET_LIST_SHOPS"
export const GET_LIST_SHOPS_SUCCESS = "GET_LIST_SHOPS_SUCCESS"
export const GET_LIST_SHOPS_FAIL = "GET_LIST_SHOPS_FAIL"

/* LIST_SHOPS DETAIL*/
export const GET_LIST_SHOP_DETAIL = "GET_LIST_SHOP_DETAIL"
export const GET_LIST_SHOP_DETAIL_SUCCESS = "GET_LIST_SHOP_DETAIL_SUCCESS"
export const GET_LIST_SHOP_DETAIL_FAIL = "GET_LIST_SHOP_DETAIL_FAIL"

/**
 * add LIST_SHOP
 */
export const CREATE_LIST_SHOP = "CREATE_LIST_SHOP"
export const CREATE_LIST_SHOP_SUCCESS = "CREATE_LIST_SHOP_SUCCESS"
export const CREATE_LIST_SHOP_FAIL = "CREATE_LIST_SHOP_FAIL"

/**
 * Edit LIST_SHOP
 */
export const UPDATE_LIST_SHOP = "UPDATE_LIST_SHOP"
export const UPDATE_LIST_SHOP_SUCCESS = "UPDATE_LIST_SHOP_SUCCESS"
export const UPDATE_LIST_SHOP_FAIL = "UPDATE_LIST_SHOP_FAIL"

/**
 * Delete LIST_SHOP
 */
export const DELETE_LIST_SHOP = "DELETE_LIST_SHOP"
export const DELETE_LIST_SHOP_SUCCESS = "DELETE_LIST_SHOP_SUCCESS"
export const DELETE_LIST_SHOP_FAIL = "DELETE_LIST_SHOP_FAIL"

//create shop user
export const CREATE_SHOP_USER = "CREATE_SHOP_USER"
export const CREATE_SHOP_USER_SUCCESS = "CREATE_SHOP_USER_SUCCESS"
export const CREATE_SHOP_USER_FAIL = "CREATE_SHOP_USER_FAIL"

export const GET_SHOP_USER = "GET_SHOP_USER"
export const GET_SHOP_USER_SUCCESS = "GET_SHOP_USER_SUCCESS"
export const GET_SHOP_USER_FAIL = "GET_SHOP_USER_FAIL"

export const GET_SHOP_USER_DETAIL = "GET_SHOP_USER_DETAIL"
export const GET_SHOP_USER_DETAIL_SUCCESS = "GET_SHOP_USER_DETAIL_SUCCESS"
export const GET_SHOP_USER_DETAIL_FAIL = "GET_SHOP_USER_DETAIL_FAIL"

export const UPDATE_SHOP_USER = "UPDATE_SHOP_USER"
export const UPDATE_SHOP_USER_SUCCESS = "UPDATE_SHOP_USER_SUCCESS"
export const UPDATE_SHOP_USER_FAIL = "UPDATE_SHOP_USER_FAIL"

export const DELETE_SHOP_USER = "DELETE_SHOP_USER"
export const DELETE_SHOP_USER_SUCCESS = "DELETE_SHOP_USER_SUCCESS"
export const DELETE_SHOP_USER_FAIL = "DELETE_SHOP_USER_FAIL"
