import React, { useEffect, useState } from "react"
import { Alert, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Modal, ModalBody } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { createShopUser } from "../../../../store/actions"

//css
import "toastr/build/toastr.min.css"

const Register = ({ show, shopId, onCloseClick }) => {
  const dispatch = useDispatch()

  const { loading, error, shopUser } = useSelector(state => ({
    userLoading: state.Shop.userLoading,
    error: state.Shop.error,
    shopUser: state.Shop.createdShopUser,
  }))

  const [toggleShow, setToggleShow] = useState(false)

  const handleValidSubmit = (e, v) => {
    if (shopId) {
      dispatch(createShopUser({ ...v, shop: shopId }))
    }
    if (shopUser?.shop) {
      onCloseClick()
    }
  }

  useEffect(() => {
    if (shopUser?.shop) {
      onCloseClick()
    }
  }, [shopUser])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col>
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="my-auto">
                  <div className="mt-4 mb-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <Row>
                        <Col xs="12">
                          {" "}
                          {error?.username && error?.username[0] && (
                            <Alert color="danger" className="mt-2">
                              {error?.username[0]}
                            </Alert>
                          )}
                        </Col>
                        <Col sm="6">
                          <div className="mb-3">
                            <AvField
                              name="username"
                              label="Username"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              required
                            />
                          </div>
                        </Col>

                        <Col sm="6">
                          <div className="mb-3">
                            <AvField
                              name="phone"
                              label="Phone"
                              className="form-control"
                              id="tel-input"
                              type="mobile"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage:
                                    "Please enter your phone number",
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage:
                                    "Your number must be 10 characters",
                                },
                                maxLength: {
                                  value: 10,
                                  errorMessage:
                                    "Your number must be 10 characters",
                                },
                              }}
                            />
                          </div>
                        </Col>

                        <Col sm="6">
                          {" "}
                          <div className="mb-3">
                            <AvField
                              name="first_name"
                              label="First Name"
                              className="form-control"
                              placeholder="Enter first name"
                              type="text"
                            />
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="mb-3">
                            <AvField
                              name="last_name"
                              label="Last Name"
                              className="form-control"
                              placeholder="Enter last name"
                              type="text"
                            />
                          </div>
                        </Col>
                        <Col sm="12">
                          <div className="mb-3">
                            <AvField
                              id="horizontal-email-Input"
                              label="Email"
                              name="email"
                              className="form-control"
                              type="email"
                              required
                            />
                          </div>
                          {error?.email && error?.email[0] && (
                            <Alert color="danger" className="mt-2">
                              {error?.email[0]}
                            </Alert>
                          )}
                        </Col>
                        <Col sm="12">
                          {" "}
                          <div className="mb-3">
                            <AvField
                              id="horizontal-email-Input"
                              name="address"
                              className="form-control"
                              type="textarea"
                              label="Address"
                            />
                          </div>
                        </Col>
                        <Col sm="12">
                          {" "}
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Password"
                              className="form-control"
                              placeholder="Enter Password "
                              type={`${
                                toggleShow == true ? "text" : "password"
                              }`}
                              required
                            />
                          </div>
                          <input
                            type="checkbox"
                            checked={toggleShow ? true : false}
                            className=""
                            onClick={() => setToggleShow(!toggleShow)}
                            readOnly
                          />
                          <label className="mx-2 ">Show password</label>
                          {error?.password && (
                            <Alert color="danger" className="mt-2">
                              {error?.password}
                            </Alert>
                          )}
                        </Col>
                      </Row>

                      <div className="mt-3 d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-sm w-25 btn-danger btn-lg "
                          onClick={onCloseClick}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-sm w-25 btn-success btn-block"
                          type="submit"
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Submit
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default Register

Register.propTypes = {
  onCloseClick: PropTypes.func,
  shopId: PropTypes.any,
  show: PropTypes.any,
}
